import React, { useEffect } from 'react'
import Card from '../Card'
import search from '../../../images/features/seo/search.png'
import bing from '../../../images/features/seo/bing.png'
import google from '../../../images/features/seo/google.png'
import duckduckgo from '../../../images/features/seo/duckduckgo.png'
import ecosia from '../../../images/features/seo/ecosia.png'
import { Parallax } from 'react-scroll-parallax'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

const Bar = ({ color, height }) => {
  return <Card class='seo-graph__bar' data-height={height} style={{ display: 'inline-block', backgroundColor: color, width: '10%', marginRight: '6%', borderRadius: 100 }} />
}

const Seo = () => {
  useEffect(() => {
    setTimeout(() => {
      gsap.registerPlugin(ScrollTrigger);

      const trigger = document.querySelector('.seo-graph').closest('section')
      const bars = document.querySelectorAll('.seo-graph__bar')

      gsap.fromTo('.seo-graph__bar', { height: '30px' }, {
        scrollTrigger: {
          trigger,
          start: () => '-=' + window.innerHeight/2,
          end:  () => "+=" + window.innerHeight/2,
          scrub: true
        },
        duration: 1,
        height: (idx) => `${bars[idx].dataset.height}`,
        ease: 'none'
      })

      gsap.fromTo('.seo-graph__search-icon', { opacity: 0, top: '100%', scaleX: 0, scaleY: 0 }, {
        scrollTrigger: {
          trigger,
          start: () => '-=' + window.innerHeight/2,
          end:  () => "+=" + window.innerHeight/2,
          scrub: true
        },
        duration: 1,
        opacity: 1,
        top: '0%',
        scaleX: 1.2,
        scaleY: 1.2,
        ease: 'none'
      })
    }, 10)
  }, [])

  return <div className='seo-graph' style={{ position: 'relative', height: '60vh', maxWidth: '100vw', aspectRatio: '1 / 1', left: '50%', transform: 'translateX(-50%)' }}>
    <div style={{ position: 'absolute', width: '70%', left: '15%', top: '10%', height: '80%', display: 'flex', alignItems: 'end' }}>
      <Bar color='#e7f8e0' height='25%' />
      <Bar color='#d4f1c7' height='20%' />
      <Bar color='#b7e8a2' height='30%' />
      <Bar color='#9bdf7c' height='50%' />
      <Bar color='#7ed657' height='70%' />
      <Bar color='#61cd32' height='100%' />
      <div className='seo-graph__search-icon' style={{ position: 'absolute', marginTop: '-10%', width: '10%', height: '10%', left: '80%' }}>
        <img src={search} style={{ width: '100%' }} alt='' />
      </div>
    </div>

    <Parallax speed={10} style={{ position: 'absolute', left: '55%', top: '20%', width: '20%', aspectRatio: '1 / 1' }}>
      <Card><img src={google} style={{ width: '100%' }} alt='Google' /></Card>
    </Parallax>
    <Parallax speed={6} style={{ position: 'absolute', left: '20%', top: '30%', width: '20%', aspectRatio: '1 / 1' }}>
      <Card><img src={bing} style={{ width: '100%' }} alt='Bing' /></Card>
    </Parallax>
    <Parallax speed={8} style={{ position: 'absolute', left: '70%', top: '60%', width: '20%', aspectRatio: '1 / 1' }}>
      <Card><img src={ecosia} style={{ width: '100%' }} alt='Ecosia' /></Card>
    </Parallax>
    <Parallax speed={5} style={{ position: 'absolute', left: '10%', top: '80%', width: '20%', aspectRatio: '1 / 1' }}>
      <Card><img src={duckduckgo} style={{ width: '100%' }} alt='DuckDuckGo' /></Card>
    </Parallax>
  </div>
}

export default Seo
