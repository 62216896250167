import React, { useEffect, useState, useRef } from 'react'
import arrowTrend from '../../../images/features/arrow-trend.png'
import { Parallax } from 'react-scroll-parallax'
import circleDots from '../../../images/features/circle-dots.png'
import Card from '../Card'
import fullStory from '../../../images/features/analytics/full-story.png'
import googleAnalytics from '../../../images/features/analytics/google-analytics.png'
import hotjar from '../../../images/features/analytics/hotjar.png'
import newRelic from '../../../images/features/analytics/new-relic.png'
import laptop from '../../../images/features/devices/laptop.png'
import mobile from '../../../images/features/devices/mobile.png'
import desktop from '../../../images/features/devices/desktop.png'

const Analytics = () => {
  const analyticsTargetRef = useRef()
  const [analyticsTargetElement, setAnalyticsTargetElement] = useState()
  useEffect(() => {
    setAnalyticsTargetElement(analyticsTargetRef.current)
  }, [])

  return <div ref={analyticsTargetRef} style={{ position: 'relative', maxHeight: '100vh', maxWidth: '60vh', aspectRatio: '1 / 1', margin: 'auto' }}>
    <img src={arrowTrend} style={{ position: 'absolute', width: '105%', top: '50%', transform: 'translateY(-52.5%)' }} alt='' />
    <Parallax rotate={[360, 270]} style={{ width: '90%', height: '90%', position: 'absolute', left: '5%', top: '5%' }} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
      <img src={circleDots} style={{ width: '100%', height: '100%' }} alt='' />
    </Parallax>
    <div style={{ position: 'absolute', width: '90%', height: '90%', top: '5%', left: '5%' }}>
      <Parallax rotate={[360, 180]} style={{ height: '100%' }} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{ position: 'absolute', left: '50%', width: '20%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[0, 180]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={fullStory} style={{ width: '100%' }} alt='Fullstory' /></Card>
            </Parallax>
          </div>
          <div style={{ position: 'absolute', top: '50%', left: '100%', width: '20%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[0, 180]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={googleAnalytics} style={{ width: '100%' }} alt='Google analytics' /></Card>
            </Parallax>
          </div>
          <div style={{ position: 'absolute', top: '50%', width: '20%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[0, 180]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={hotjar} style={{ width: '100%' }} alt='Hotjar' /></Card>
            </Parallax>
          </div>
          <div style={{ position: 'absolute', top: '100%', left: '50%', width: '20%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[0, 180]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={newRelic} style={{ width: '100%' }} alt='New Relic' /></Card>
            </Parallax>
          </div>
        </div>
      </Parallax>
    </div>

    <div style={{ position: 'absolute', width: '25%', height: '25%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <Parallax rotate={[180, 360]} style={{ height: '100%' }} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{ position: 'absolute', left: '50%', width: '60%', top: '0%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[180, 0]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={laptop} style={{ width: '100%' }} alt='' /></Card>
            </Parallax>
          </div>
          <div style={{ position: 'absolute', top: '86%', width: '60%', left: '100%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[180, 0]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={mobile} style={{ width: '100%' }} alt='' /></Card>
            </Parallax>
          </div>
          <div style={{ position: 'absolute', top: '86%', width: '60%', transform: 'translate(-50%, -50%)'}}>
            <Parallax rotate={[180, 0]} targetElement={analyticsTargetElement} shouldAlwaysCompleteAnimation>
              <Card><img src={desktop} style={{ width: '100%' }} alt='' /></Card>
            </Parallax>
          </div>
        </div>
      </Parallax>
    </div>
  </div>
}

export default Analytics
