import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import Home from './Pages/Home'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import Features from './Pages/Features'

const App = () =>
  <BrowserRouter>
    <Routes>
      <Route path='/features' element={<Features />} />
      <Route path='/' element={
        <ParallaxProvider>
          <Home />
        </ParallaxProvider>
      } />
    </Routes>
  </BrowserRouter>

export default App
