import Point from './Point'

export default class Blob {
  constructor() {
    this.points = [];
  }

  init() {
    for (let i = 0; i < this.numPoints; i++) {
      let point = new Point(this.divisional * ( i + 1 ), this);
      point.friction = 0.07;
      this.push(point);
    }
  }

  render() {
    let canvas = this.canvas;
    let ctx = this.ctx;
    let pointsArray = this.points;
    let points = this.numPoints;
    let center = this.center;

    ctx.clearRect(0,0,canvas.width,canvas.height);

    pointsArray[0].solveWith(pointsArray[points-1], pointsArray[1]);

    let p0 = pointsArray[points-1].position;
    let p1 = pointsArray[0].position;
    let _p2 = p1;

    ctx.beginPath();
    ctx.moveTo(center.x, center.y);
    ctx.moveTo((p0.x + p1.x) / 2, (p0.y + p1.y) / 2);

    let sumX = 0
    let sumY = 0

    for (let i = 1; i < points; i++) {
      pointsArray[i].solveWith(pointsArray[i - 1], pointsArray[i + 1] || pointsArray[0]);

      let p2 = pointsArray[i].position
      const xc = (p1.x + p2.x) / 2
      const yc = (p1.y + p2.y) / 2

      sumX += xc
      sumY += yc
      ctx.quadraticCurveTo(p1.x, p1.y, xc, yc)

      p1 = p2
    }

    const xc = (p1.x + _p2.x) / 2;
    const yc = (p1.y + _p2.y) / 2;

    sumX += xc
    sumY += yc
    ctx.quadraticCurveTo(p1.x, p1.y, xc, yc);

    ctx.shadowColor = 'rgba(113, 77, 244, 1)';
    ctx.shadowBlur = 30;
    ctx.shadowOffsetY = 10;

    ctx.fillStyle = this.color;
    ctx.fill();

    ctx.shadowBlur = 0;

    ctx.fillStyle = this.textColor
    ctx.font='600 26px "Font Awesome 5 Free"'
    ctx.textAlign = "center"
    ctx.fillText(this.icon,(sumX/this.numPoints), (sumY/this.numPoints) + 10)


    requestAnimationFrame(this.render.bind(this))
  }

  push(item) {
    if (item instanceof Point) {
      this.points.push(item)
    }
  }

  set color(value) {
    this._color = value
  }

  get color() {
    return this._color || '#000000'
  }

  set textColor(value) {
    this._textColor = value
  }

  get textColor() {
    return this._textColor || '#ffffff'
  }

  set icon(value) {
    this._icon = value
  }

  get icon() {
    return this._icon
  }

  set canvas(value) {
    if(value instanceof HTMLElement && value.tagName.toLowerCase() === 'canvas') {
      this._canvas = value
      this.ctx = this._canvas.getContext('2d')
    }
  }

  get canvas() {
    return this._canvas
  }

  set numPoints(value) {
    if(value > 2) {
      this._points = value
    }
  }

  get numPoints() {
    return this._points || 8
  }

  set radius(value) {
    if (value > 0) {
      this._radius = value
    }
  }

  get radius() {
    return this._radius || 150
  }

  set position(value) {
    if (typeof value == 'object' && value.x && value.y) {
      this._position = value
    }
  }

  get position() {
    return this._position || { x: 0.5, y: 0.5 }
  }

  get divisional() {
    return Math.PI * 2 / this.numPoints
  }

  get center() {
    return { x: this.canvas.width * this.position.x, y: this.canvas.height * this.position.y }
  }

  set running(value) {
    this._running = value === true
  }

  get running() {
    return this.running !== false
  }
}
