import React from 'react'
import './footer.css'
import logo from '../../../images/logo-color.svg'
import Text from '../Typography/Text'
import SubHeading from '../Typography/SubHeading'

const Footer = ({ enableSnap }) => {
  return <footer className={`footer ${enableSnap && 'scroll-snap-align'}`}>
    <div className='container'>
      <div className='row'>
        <div className='col-auto'>
          <img src={logo} className='footer__logo' alt='' aria-hidden={true} />
        </div>
        <div className='col'>
          <div className='row'>
            <div className='col-sm-4'>
              <SubHeading small color='#5D5D5D'>Customers</SubHeading>
              <Text small color='#5D5D5D'>
                <a href='http://docs.jgtech.uk' target='_blank' rel="noreferrer">Product Documentation <i className='fa fa-external-link-alt' /></a>
              </Text>
              <Text small color='#5D5D5D'>
                <a href='https://jgregoryltd.atlassian.net/servicedesk' target='_blank' rel="noreferrer">Self Service <i className='fa fa-external-link-alt' /></a>
              </Text>
            </div>

            <div className='col-sm-4'>
              <SubHeading small color='#5D5D5D'>Product &amp; Features</SubHeading>
              <Text small color='#5D5D5D'>
                <a href='/features'>All Product Features</a>
              </Text>
            </div>

            <div className='col-sm-4'>
              <SubHeading small color='#5D5D5D'>Resources</SubHeading>
              <Text small color='#5D5D5D'>
                <a href='https://jgtech.uk/blog' target='_blank' rel="noreferrer">Read Our Blog <i className='fa fa-external-link-alt' /></a>
              </Text>
            </div>
          </div>

          <div style={{ marginTop: '5rem' }}>
            <Text small color='#5D5D5D'>
              For more information, queries, or a product demo, get in touch
              at <a href='mailto:contact@jgtech.uk'>contact@jgtech.uk</a>.
            </Text>
            <Text small color='#5D5D5D'>
              <small>
                J. Gregory Technology Ltd is a company registered England and Wales
                (Company No. 12823120), 20a Oak Tree Drive Dukinfield SK16&nbsp;5ER.
              </small>
            </Text>
            <Text small color='#5D5D5D'>
              <small>
                Copyright &copy; {(new Date()).getUTCFullYear()}, J. Gregory Technology Ltd.
              </small>
            </Text>
          </div>
        </div>
      </div>
    </div>
  </footer>
}

export default Footer
