import React, { useRef, useEffect, useState } from 'react'
import PageSection from '../../Components/PageSection'
import gradientBg from '../../../images/background-gradient.svg'
import Heading from '../../Components/Typography/Heading'
import Text from '../../Components/Typography/Text'
import { Parallax } from 'react-scroll-parallax'
import ScrollButton from '../../Components/ScrollButton'
import logo from '../../../images/logo-white.svg'
import jupiter from '../../../images/jupiter.jpeg'
import Navigation from '../../Components/Navigation'
import FadeInOnScroll from '../../Components/FadeInOnScroll'
import SubHeading from '../../Components/Typography/SubHeading'
import MoonSection from '../../Components/MoonSection'
import callisto from '../../../images/moons/callisto.jpeg'
import io from '../../../images/moons/io.jpeg'
import europa from '../../../images/moons/europa.jpeg'
import lazyfood from '../../../images/features/lazyfood.png'
import zhannetHandmade from '../../../images/features/zhannet-handmade.png'
import Card from '../../Components/Card'
import stockNotifications from '../../../images/features/stock-notifications.png'
import lowStock from '../../../images/features/low-stock.png'
import noStock from '../../../images/features/no-stock.png'
import Subscriptions from '../../Components/Features/Subscriptions'
import Analytics from '../../Components/Features/Analytics'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Payments from '../../Components/Features/Payments'
import Seo from '../../Components/Features/Seo'
import screen from '../../../images/screen.png'
import demo from '../../../images/demo.png'
import Accessibility from '../../Components/Features/Accessibility'
import WhyUs from '../../Components/WhyUs'
import Footer from '../../Components/Footer'
import Helmet from 'react-helmet'
import Authentication from '../../Components/Features/Authentication'

const Home = () => {
  gsap.registerPlugin(ScrollTrigger)

  const sectionsRefs = {
    products: useRef(),
    features: useRef(),
    demo: useRef(),
    whyUs: useRef(),
    about: useRef()
  }

  const demoWrapperRef = useRef()
  const demoRef = useRef()
  const demoImageRef = useRef()

  const [sectionElements, setSectionElements] = useState()
  const [demoWrapper, setDemoWrapper] = useState()

  useEffect(() => {
    setDemoWrapper(demoWrapperRef.current)

    const elements = Object.entries(sectionsRefs).map(([key, ref]) => [key, ref.current])
    setSectionElements(Object.fromEntries(elements))

    const horizontalSections = gsap.utils.toArray('.products-section')

    horizontalSections.forEach(function (sec, i) {
      const thisPinWrap = sec.querySelector('.pin-wrap');
      const thisAnimWrap = thisPinWrap.querySelector('.animation-wrap');

      const getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth)

      gsap.fromTo(thisAnimWrap, {
        x: () => thisAnimWrap.classList.contains('to-right') ? 0 : getToValue()
      }, {
        x: () => thisAnimWrap.classList.contains('to-right') ? getToValue() : 0,
        ease: "none",
        scrollTrigger: {
          trigger: sec,
          start: "top top",
          end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth),
          pin: thisPinWrap,
          invalidateOnRefresh: true,
          scrub: true
        }
      })
    })

    setTimeout(() => {
      ScrollTrigger.create({
        trigger: demoRef.current,
        pin: true,
        end: () => {
          const demoScrollHeight = demoImageRef.current.clientHeight
            - demoRef.current.clientHeight
            + (demoRef.current.clientHeight * 0.09)

          return `+=${demoScrollHeight}`
        },
        start: 'center 50%+=50px',
        pinSpacing: false,
      })
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <Helmet>
      <link rel='canonical' href='https://jgtech.uk/' />
    </Helmet>
    <PageSection background={gradientBg} id='home'>
      <div style={{ position: 'absolute', left: '50%', top: '60%', width: '90vw', transform: 'translate(-50%, -50%)'}}>
        <Parallax translateY={[0, -150]} opacity={[1, 0]} shouldAlwaysCompleteAnimation>
          <div style={{ textAlign: 'center', paddingBottom: 40 }}>
            <img src={logo} height={100} alt='J Gregory Technology'/>
          </div>
        </Parallax>
        <Parallax translateY={[0, -150]} opacity={[1, 0]} shouldAlwaysCompleteAnimation>
          <Heading  color='white' center>Excel in ecommerce</Heading>
        </Parallax>
        <Parallax translateY={[0, -100]} opacity={[1, 0]} shouldAlwaysCompleteAnimation>
          <Text color='white' center>Ecommerce software tailored to your business</Text>
        </Parallax>
        <Parallax translateY={[0, -50]} opacity={[1, 0]} shouldAlwaysCompleteAnimation>
          <a href='#products' style={{ position: 'relative', left: '50%', display: 'inline-block', transform: 'translateX(-50%)' }}>
            <ScrollButton />
          </a>
        </Parallax>
      </div>
    </PageSection>

    <div style={{ position: 'relative' }}>
      <Navigation duration={2000} sections={sectionElements} />

      <div style={{ marginTop: 0 }} id='products' ref={sectionsRefs.products}>
        <PageSection background='black' style={{ paddingBottom: 50, height: 'calc(100vh + 100px)' }}>
          <div style={{ position: 'relative', zIndex: 1 }}>
            <Parallax speed={10}>
              <div style={{ paddingTop: 200 }}>
                <FadeInOnScroll duration={1600} offset={100}>
                  <Heading color='white' center>Introducing the Jovian Product Suite</Heading>
                </FadeInOnScroll>
                <FadeInOnScroll duration={1600} offset={100}>
                  <Text color='white' center>An infinity configurable ecommerce ecosystem</Text>
                </FadeInOnScroll>
              </div>
              <div style={{ paddingTop: 50, paddingBottom: 100 }}>
                <FadeInOnScroll duration={1800} offset={100}>
                  <SubHeading color='white' center>Comprised of 3 outstanding products</SubHeading>
                </FadeInOnScroll>
              </div>
            </Parallax>
          </div>

          <FadeInOnScroll offset={100} duration={2200}>
            <Parallax opacity={[2, -1]} shouldAlwaysCompleteAnimation>
              <img src={jupiter} alt='' style={{ width: '100vw', position: 'relative', left: '50%', transform: 'translateX(-50%)' }} />
            </Parallax>
          </FadeInOnScroll>
        </PageSection>

        <PageSection background='black' style={{ scrollSnapAlign: 'none' }}>
          <div style={{ position: 'absolute', zIndex: 1000, display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
            <div className='scroll-section scroll-snap-align' />
            <div className='scroll-section scroll-snap-align' />
            <div className='scroll-section scroll-snap-align' />
          </div>

          <div className='products-section' style={{ scrollSnapType: 'y mandatory', position: 'relative', overflowX: 'hidden', width: '100%' }}>
            <div className='pin-wrap' style={{ display: 'flex', position: 'relative', top: '0', zIndex: 1, height: '100vh' }}>
              <div className='animation-wrap to-right' style={{ display: 'flex', position: 'relative', zIndex: 1, height: '100vh' }}>
                <MoonSection title='Europa' description='A beautifully polished customer facing website. Tailored specifically to your brands requirements.' image={europa} alt='Europa' />
                <MoonSection title='Io' description='Manage everything from discount codes to your stock levels through our simple yet powerful admin system.' image={io} alt='Io' />
                <MoonSection title='Callisto' description='Want even more flexibility? Our advanced API allows you to create a 100% unique experience.' image={callisto} alt='Callisto' />
              </div>
            </div>
          </div>
        </PageSection>
      </div>

      <div id='features' style={{ paddingTop: '10rem' }} ref={sectionsRefs.features}>
        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12'>
                <Heading>Customised to your brand</Heading>
                <Text>
                  We offer bespoke builds of our customer-facing e-commerce website, and
                  configure and manage your unique web address and custom emails addresses.
                </Text>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div style={{ position: 'relative', height: '60vh' }}>
                  <div style={{ position: 'absolute', paddingLeft: '20%', top: '40%', transform: 'translateY(-50%)' }}>
                    <Parallax speed={10}>
                      <Card>
                        <img src={lazyfood} alt='Lazyfood Website product page' style={{ width: '100%' }} />
                      </Card>
                    </Parallax>
                  </div>
                  <div style={{ position: 'absolute', top: '40%', transform: 'translateY(0%)', left: '0', width: '70%' }}>
                    <Parallax speed={20}>
                      <Card>
                        <img src={zhannetHandmade} alt='Zhannet Handmade Website Orders Page' style={{ width: '100%' }} />
                      </Card>
                    </Parallax>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12 order-md-2'>
                <Heading>Keep stock of your stock</Heading>
                <Text>
                  Receive notifications when any of your products get low on stock.
                </Text>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div style={{ position: 'relative', height: '60vh', maxWidth: '80vh', margin: 'auto' }}>
                  <div style={{ position: 'absolute', marginLeft: '0%', width: '50%', top: '55%', transform: 'translateY(-50%)' }}>
                    <Parallax speed={10}>
                      <Card><img src={lowStock} style={{ width: '100%' }} alt='Low stock products report' /></Card>
                    </Parallax>
                  </div>
                  <div style={{ position: 'absolute', marginLeft: '45%', width: '50%', top: '70%', transform: 'translateY(-50%)' }}>
                    <Parallax speed={20}>
                      <Card><img src={noStock} style={{ width: '100%' }} alt='Depleted stock products report' /></Card>
                    </Parallax>
                  </div>
                  <div style={{ position: 'absolute', marginLeft: '20%', width: '70%', top: '30%', transform: 'translateY(-50%)' }}>
                    <Parallax speed={15}>
                      <Card><img src={stockNotifications} style={{ width: '100%' }} alt='Low stock notification alerts' /></Card>
                    </Parallax>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12 order-md-2'>
                <Heading>Hassle-free Subscriptions</Heading>
                <Text>
                  Multi-product subscription support included out of the box. Allow your customers
                  to create and manage subscriptions effortlessly.
                </Text>
              </div>
              <div className='col-md-6 col-sm-12'>
                <Subscriptions />
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12'>
                <Heading>Integrated Payments</Heading>
                <Text>
                  Build trust by accepting credit/debit cards and PayPal directly on your website domain. Enable card
                  storage for convenience and increase future sales conversions.
                </Text>
              </div>

              <div className='col-md-6 col-sm-12'>
                <Payments />
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12 order-md-2'>
                <Heading>Secure by design</Heading>
                <Text>
                  Enhance your business’s security with multi-factor authentication and granular user controls, ensuring
                  the safety of both your valuable business data and customer information.
                </Text>
              </div>
              <div className='col-md-6 col-sm-12'>
                <Authentication />
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12'>
                <Heading>Accessible to All</Heading>
                <Text>
                  Our services are designed to meet the latest accessibility standards, helping
                  you to cater to the largest possible audience.
                </Text>
              </div>

              <div className='col-md-6 col-sm-12'>
                <Accessibility />
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12 order-md-2'>
                <Heading>Automatically Searchable</Heading>
                <Text>
                  Effortlessly improve your SEO. Our solution will automatically request search engine indexing for
                  new products and define SEO-improving metadata so you don't have to.
                </Text>
              </div>
              <div className='col-md-6 col-sm-12'>
                <Seo />
              </div>
            </div>
          </div>
        </PageSection>

        <PageSection background='white'>
          <div className='container-fluid' style={{ padding: '0 5rem' }}>
            <div className='row align-items-center' style={{ minHeight: '100vh' }}>
              <div className='col-md-6 col-sm-12'>
                <Heading>Superior User Analysis</Heading>
                <Text>
                  All of our solutions come with built in support Google Analytics, one of the most
                  powerful analytics solutions on the market. But is capable of supporting most user
                  analytics platforms.
                </Text>
              </div>
              <div className='col-md-6'>
                <Analytics />
              </div>
            </div>
          </div>
        </PageSection>
      </div>

      <div id='whyUs' ref={sectionsRefs.whyUs}>
        <PageSection background='white'>
          <WhyUs />
        </PageSection>
      </div>

      <div id='demo' ref={sectionsRefs.demo}>
        <PageSection background='white' style={{ paddingBottom: 300, overflow: 'hidden' }}>
          <div style={{ paddingTop: 200, paddingBottom: 100, zIndex: 1, position: 'relative' }}>
            <Parallax translateY={[0, -150]}>
              <Heading center>For a demo, contact us</Heading>
            </Parallax>
            <Parallax translateY={[0, -100]}>
              <Text center>
                Email us for a full system demo at <a href="mailto:contact@jgtech.uk">contact@jgtech.uk</a>
              </Text>
            </Parallax>
          </div>

          <div style={{ zIndex: 0, position: 'relative', width: '100vw', maxWidth: '130vh', aspectRatio: '1.83', margin: 'auto' }} className='demo-wrapper' ref={demoWrapperRef}>
            <div style={{ position: 'relative', width: '72%', left: '14%' }}>
              <Parallax scale={[1,1.1]} targetElement={demoWrapper}>
                <img src={demo} style={{ width: '100%' }} alt='Io product homepage dashboard preview' ref={demoImageRef} />
              </Parallax>
            </div>

            <div ref={demoRef} style={{ position: 'absolute', top: 0, width: '100%', aspectRatio: '1.83' }}>
              <Parallax scale={[1,1.1]} targetElement={demoWrapper}>
                <img src={screen} style={{ position: 'absolute', top: 0, width: '100%', transform: 'translateY(-40.5%)' }} alt='' />
              </Parallax>
            </div>
          </div>
        </PageSection>
      </div>

      <div ref={sectionsRefs.about} className='container-fluid'>
        <PageSection background='white' style={{ paddingTop: 200 }} id='about'>
          <div style={{ width: '90vw', maxWidth: 800, margin: 'auto' }}>
          <Heading>The need for something different</Heading>
          <Text small={true}>
            Our software was born back in 2018, with the idea for a subscription-based
            easy-to-cook food delivery service. Existing solutions relied heavily on
            third-party plugins for subscription functionality, or were hard-to-update
            self-hosted monoliths.
          </Text>
          <Text small>
            <strong>We believed that we could do better.</strong>
          </Text>
          <Text small>
            While building our delivery service, and noticing the rise of subscription
            e-commerce, we realised that our software could benefit others. So we
            changed tack. Today, we have built a lean, fully managed, and customisable
            e-commerce platform with many of the enhancements usually provided by plugins
            included as standard.
          </Text>
          </div>
        </PageSection>
      </div>
    </div>

    <Footer enableSnap />
  </>
}

export default Home
