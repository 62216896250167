import React from 'react'
import './pageSection.css'

const PageSection = ({ children, background, style, ...props }) => {
  const backgroundCssProps = background.includes('/')
    ? {
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    } : {
      backgroundColor: background
    }

  return <section {...props} className='page-section' style={{ ...style, ...backgroundCssProps }}>
    {children}
  </section>
}

export default PageSection
