import React, { useEffect } from 'react'
import SubHeading from '../Typography/SubHeading'
import Text from '../Typography/Text'
import Heading from '../Typography/Heading'
import WhyUsIcon from './WhyUsIcon'
import dotPath from '../../../images/dot-path.svg'
import gsap from 'gsap'
import growth from '../../../images/why-us/growth.svg'
import support from '../../../images/why-us/support.svg'
import partnership from '../../../images/why-us/partnership.svg'

const WhyUs = () => {
  useEffect(() => {
    gsap.to(".dot-path", {duration: 1, drawSVG: "20% 80%"})
  }, [])

  return <div className='container-fluid' style={{ maxWidth: 800, paddingTop: '150px', paddingBottom: '150px' }}>
    <Heading center>Why Us?</Heading>

    <div className='row align-items-center' style={{ position: 'relative', paddingTop: '100px', paddingBottom: '100px' }}>
      <div className='col-md-9 col-sm-12 order-md-2'>
        <SubHeading>Growth</SubHeading>
        <Text>
          Whether you are re-platforming, or new to the world of online sales,
          our system can be used to boost your sales and revenue.
        </Text>
      </div>
      <div className='col-md-3 col-sm-12'>
        <WhyUsIcon color='113,77,244' icon={growth} />
      </div>

      <div style={{position: 'absolute', width: '75%', left: '12.5%', top: 'calc(100% - 100px)' }}>
        <img src={dotPath} alt='' style={{ width: '100%' }} />
      </div>
    </div>

    <div className='row align-items-center' style={{ position: 'relative', padding: '100px 0'}}>
      <div className='col-md-9 col-sm-12'>
        <SubHeading>Support</SubHeading>
        <Text>
          We guide you and your business at every point on your journey with us,
          from our initial ideas meeting, through to staff training, website launch,
          and future ongoing support.
        </Text>
      </div>
      <div className='col-md-3 col-sm-12 '>
        <WhyUsIcon color='55,194,234' icon={support} />
      </div>

      <div style={{position: 'absolute', width: '75%', transform: 'rotateX(-180deg)', left: '12.5%', top: 'calc(100% - 100px)' }}>
        <img src={dotPath} alt='' style={{ width: '100%' }} />
      </div>
    </div>

    <div className='row align-items-center' style={{ padding: '100px 0'}}>
      <div className='col-md-9 col-sm-12 order-md-2'>
        <SubHeading>Partnership</SubHeading>
        <Text>
          Using our system as a platform to propel you in the e-commerce space, we
          will work closely with you and your business to build, launch, and maintain
          your unique online store for the long term.
        </Text>
      </div>
      <div className='col-md-3 col-sm-12'>
        <WhyUsIcon color='242,81,180' icon={partnership} />
      </div>
    </div>
  </div>
}

export default WhyUs
