import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import Card from '../Card'
import paymentImg1 from '../../../images/features/payments/payment-1.jpeg'
import paymentImg2 from '../../../images/features/payments/payment-2.jpeg'
import visa from '../../../images/features/payments/cards/visa.png'
import unionPay from '../../../images/features/payments/cards/unionpay.png'
import mastercard from '../../../images/features/payments/cards/mastercard.png'
import discover from '../../../images/features/payments/cards/discover.png'
import diners from '../../../images/features/payments/cards/diners.png'
import jcb from '../../../images/features/payments/cards/jcb.png'
import paypal from '../../../images/features/payments/cards/paypal.png'
import maestro from '../../../images/features/payments/cards/maestro.png'
import amex from '../../../images/features/payments/cards/amex.png'

const Payments = () => {
  return <div style={{ position: 'relative', height: '90vh', maxWidth: 800, margin: 'auto' }}>
    <div style={{ position: 'relative', height: '50vh', top: '50%', transform: 'translateY(-50%)' }}>
      <div style={{ position: 'absolute', left: 0, top: 0, maxWidth: '40%' }}>
        <Parallax speed={20}>
          <Card><img src={paymentImg1} style={{ width: '100%' }} alt='Couple shopping online'/></Card>
        </Parallax>
      </div>

      <div style={{ position: 'absolute', right: 0, bottom: 0, maxWidth: '30%' }}>
        <Parallax speed={25}>
          <Card><img src={paymentImg2} style={{ width: '100%' }} alt='Man shopping on mobile phone'/></Card>
        </Parallax>
      </div>

      <div style={{ position: 'absolute', right: '10%', top: 0, minWidth: 80, width: '15%' }}>
        <Parallax speed={24}>
          <Card style={{ borderRadius: 5 }}><img src={visa} style={{ width: '100%' }} alt='Visa' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '50%', top: '5%', minWidth: 80, width: '15%' }}>
        <Parallax speed={14}>
          <Card style={{ borderRadius: 5 }}><img src={unionPay} style={{ width: '100%' }} alt='Union Pay' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '68%', top: '20%', minWidth: 80, width: '15%' }}>
        <Parallax speed={21}>
          <Card style={{ borderRadius: 5 }}><img src={mastercard} style={{ width: '100%' }} alt='Mastercard' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '45%', top: '25%', minWidth: 80, width: '15%' }}>
        <Parallax speed={16}>
          <Card style={{ borderRadius: 5 }}><img src={discover} style={{ width: '100%' }} alt='Discover' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '35%', top: '45%', minWidth: 80, width: '15%' }}>
        <Parallax speed={22}>
          <Card style={{ borderRadius: 5 }}><img src={diners} style={{ width: '100%' }} alt='Diners' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '10%', top: '50%', minWidth: 80, width: '15%' }}>
        <Parallax speed={20}>
          <Card style={{ borderRadius: 5 }}><img src={jcb} style={{ width: '100%' }} alt='JCB' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '45%', top: '65%', minWidth: 80, width: '15%' }}>
        <Parallax speed={25}>
          <Card style={{ borderRadius: 5 }}><img src={paypal} style={{ width: '100%' }} alt='PayPal' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: '20%', top: '75%', minWidth: 80, width: '15%' }}>
        <Parallax speed={21}>
          <Card style={{ borderRadius: 5 }}><img src={maestro} style={{ width: '100%' }} alt='Maestro' /></Card>
        </Parallax>
      </div>
      <div style={{ position: 'absolute', left: 0, top: '85%', minWidth: 80, width: '15%' }}>
        <Parallax speed={23}>
          <Card style={{ borderRadius: 5 }}><img src={amex} style={{ width: '100%' }} alt='American Express' /></Card>
        </Parallax>
      </div>
    </div>
  </div>
}

export default Payments
