import React, { useState, useRef } from 'react'

const FadeInOnScroll = ({ children, offset = 200, duration = 2000, direction = 'vertical', ...props }) => {
  const domRef = useRef()
  const [isVisible, setVisible] = useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      // In your case there's only one element to observe:
      if (entries[0].isIntersecting) {

        // Not possible to set it back to false like this:
        setVisible(true)

        // No need to keep observing:
        observer.unobserve(domRef.current)
      }
    });

    observer.observe(domRef.current)

    return () => observer.disconnect()
  }, [])

  const style = {
    opacity: 0,
    visibility: 'hidden',
    transition: `opacity ${duration}ms ease-out, transform ${duration}ms ease-out`,
    transform: `translate${direction === 'vertical' ? 'Y' : 'X'}(${offset}px)`,
    willChange: 'opacity, visibility, transform'
  }

  const visibleStyle = {
    opacity: 1,
    transform: 'translateY(0)',
    visibility: 'visible'
  }

  return <div ref={domRef} style={{
    ...style,
    ...(isVisible ? visibleStyle : {}),
    ...props.style
  }}>
    {children}
  </div>
}

export default FadeInOnScroll
