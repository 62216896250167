import React from 'react'
import Heading from '../../Components/Typography/Heading'
import SubHeading from '../../Components/Typography/SubHeading'
import Text from '../../Components/Typography/Text'
import Footer from '../../Components/Footer'
import { Helmet } from 'react-helmet'

const convertToSlug = (str) => {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

const Features = () => {
  const sections = [
    {
      title: 'Customer Account Management',
      items: [
        {
          heading: 'Sign up and login',
          text: 'Allow your customers to create an account on your ecommerce site, enabling them to save their basket items, track order progress, and view previous orders.'
        },
        {
          heading: 'Forgot password',
          text: 'Securely enable your customers to reset their account password if they lose it, reducing the time required by you addressing support queries.'
        },
        {
          heading: 'Change email address',
          text: 'Enable your customers to change their notification email address, and email address used to access their account. This is managed securely, requiring confirmation of the new email address, and an alert being sent to inform the customer of the change.'
        },
        {
          heading: 'Delete account',
          text: 'A GDPR requirement – allow your customers to remove their account and associated data from the system. This is handled automatically by the system, ensuring you are compliant without any extra effort.'
        },
        {
          heading: 'Change password',
          text: 'Allow your customers to change their account password securely within your ecommerce site.'
        }
      ]
    },
    {
      title: 'Customer Emails',
      items: [
        {
          heading: 'Order Notification',
          text: 'Notify your customers when an order has been placed or dispatched, a payment has been taken for an upcoming subscription order, or alert them that their order has been amended.'
        },
        {
          heading: 'Email Security Alerts',
          text: 'Keep your customer informed of any important changes to their account, such as a changed email address or password, or the addition/removal of a stored payment method.'
        },
        {
          heading: 'Custom Branding',
          text: 'Have consistency across your brand with fully customised system emails.'
        }
      ]
    },
    {
      title: 'Customer Baskets',
      items: [
        {
          heading: 'Synced account baskets',
          text: 'No matter where they login, ensure your customers can carry their baskets wherever they go with basket items stored on their account.'
        },
        {
          heading: 'Non-account baskets',
          text: 'Allow new customers to add products to their basket without making them create an account. Basket contents is stored between website visits for returning potential customers.'
        },
        {
          heading: 'Promotions and discounts',
          text: 'Show relevant promotions/discounts & total savings directly in the customer’s basket.'
        }
      ]
    },
    {
      title: 'Products',
      items: [
        {
          heading: 'Full product search',
          text: 'A comprehensive, quick, and accurate product search capable of indexing product titles and their descriptions. Out of the box support for ‘fuzzy-functionality’, allowing leeway for misspellings.'
        },
        {
          heading: 'Unlimited product variations',
          text: 'Give your customers choice with the ability to add variations to your products.'
        },
        {
          heading: 'Media Gallery Support',
          text: 'Show off your products by adding multiple images and videos for each product or product variation. Image gallery support allows customers to focus and zoom in on individual images for a closer look.'
        },
        {
          heading: 'Product Category Hierarchies',
          text: 'Organise your products into categories to enable easy and quick navigation of your site. Out of the box support for single and multiple-level category hierarchies to support catalogues of all sizes.'
        },
        {
          heading: 'Custom Properties',
          text: 'Define the different product fields available for different product categories using an expressive schema language.'
        },
        {
          heading: 'Dynamic Product Categories',
          text: 'Automate product category lists with our powerful yet simple query builder.'
        }
      ]
    },
    {
      title: 'Deliveries',
      items: [
        {
          heading: 'Preferred delivery times',
          text: 'Allow your users to select their preferred delivery dates for applicable delivery methods for both one-time and subscription orders.'
        },
        {
          heading: 'Address Book',
          text: 'Allow customers to store multiple addresses on their account, allow them to easily select their preferred delivery address for orders, or to use as a billing address for a payment method.'
        },
        {
          heading: 'Change delivery information for upcoming orders',
          text: 'Give your customer flexibility by allowing them to change their delivery address and preferred delivery times for upcoming subscription orders without the need for support calls.'
        }
      ]
    },
    {
      title: 'Orders',
      items: [
        {
          heading: 'Creating one-time orders',
          text: 'Allow your users to create one-off orders on your ecommerce site.'
        },
        {
          heading: 'Create subscription orders',
          text: 'Ensure recurring revenue by enabling your customer to create subscription orders on your site.'
        },
        {
          heading: 'Modify upcoming order products',
          text: 'Allow your customers to change the contents of upcoming subscription orders prior to payment being taken.'
        },
        {
          heading: 'View order statuses',
          text: 'Keep your customers up to date with order and delivery statuses, such as order processing and dispatch details. All simply manageable through your admin system.'
        },
        {
          heading: 'Order Timeline',
          text: 'Present to your customers both their one-time and subscription orders in a single, clear, and concise timeline view. The most recent orders are displayed first.'
        }
      ]
    },
    {
      title: 'Payments',
      items: [
        {
          heading: 'Multiple Payment Methods',
          text: 'Allow your customers to pay securely via PayPal, debit or credit card. All major card issuers (Visa, MasterCard, Amex, UnionPay, etc.) have first class support via our payment processor, Braintree.'
        },
        {
          heading: 'Stored multiple methods',
          text: 'Allow your customers to securely store multiple payment methods within their account for use in upcoming subscription orders, or for one off payments.'
        },
        {
          heading: 'Expiring and Expired Card Notifications',
          text: 'Our system automatically notifies your customers when a stored card is due to - or has already - expired.'
        },
        {
          heading: 'Automatic Retry',
          text: 'Sometimes payment failures happen. Our system will attempt to retake a failed payment for subscription orders before automatic cancellation.'
        }
      ]
    },
    {
      title: 'Offers and Discounts',
      items: [
        {
          heading: 'Coupon Codes',
          text: 'Create to increase conversion and to track marketing campaign effectiveness.'
        },
        {
          heading: 'Offer Eligibility',
          text: 'Control who can see particular offers based on order value or product quantities.'
        },
        {
          heading: 'Sale Campaigns',
          text: 'Easily create long running or flash sales with fine control over offer details and product eligibility.'
        }
      ]
    },
    {
      title: 'Security',
      items: [
        {
          heading: 'Multi Factor Authentication',
          text: 'Ensure the safety of your business with multi factor authentication.'
        }
      ]
    },
    {
      title: 'Legal',
      items: [
        {
          heading: '3DS2 support',
          text: '3D Secure (version 2) supported for all credit/debit cards out of the box for frictionless checkouts and reduced fraudulent transactions.'
        },
        {
          heading: 'Right to Erasure',
          text: 'Our system enables customers to delete their account, in compliance with Article 17 of the GDPR.'
        },
        {
          heading: 'Cookie Consent',
          text: 'Out of the box cookie banner support.'
        }
      ]
    }
  ]

  return <>
    <Helmet>
      <title>All Product Features - J Gregory Technology</title>
      <meta
        name='description'
        content='Find the full range of features related to our offering here. For more information or a demo please contact us.'
      />
    </Helmet>
    <nav style={{
      position: 'sticky',
      top: 0,
      zIndex: 9999,
      width: '100vw',
      backgroundColor: 'rgba(255,255,255,0.7)',
      transitionProperty: 'color',
      transitionDuration: '0.2s',
      backdropFilter: 'blur(10px)'
    }}>
      <div className='container' style={{
        paddingTop: 15,
        color: '#535353',
      }}>
        <ul className='nav__list'>
          <li style={{ color: '#5d5d5d', transition: '0.2' }}>
            <a href='/'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.5 98.3" fill="currentColor" height={50}>
                <path
                  d="M30.3 62.1a48.4 48.4 0 0 1-1.8-13v9.1a18.3 18.3 0 0 1-1.5 7.4 19.1 19.1 0 0 1-10.2 10.1 18.6 18.6 0 0 1-7.4 1.5 18.9 18.9 0 0 1-5-.7 18.2 18.2 0 0 1-4.6-1.9V95a39.3 39.3 0 0 0 9.6 1.2 37.1 37.1 0 0 0 14.8-3 38.3 38.3 0 0 0 12.2-8.1 37.5 37.5 0 0 0 3.7-4.3 47.9 47.9 0 0 1-4.9-6.9 50 50 0 0 1-4.9-11.8zm5-37.8a48 48 0 0 1 7.7-10 50.2 50.2 0 0 1 4.6-4V1h-19v48a47.7 47.7 0 0 1 1.8-13 50.4 50.4 0 0 1 4.9-11.7z"/>
                <path
                  d="M49.9 37.3a30.5 30.5 0 0 1 6.5-9.6 31.2 31.2 0 0 1 9.6-6.5 29.7 29.7 0 0 1 23.6.1 31.9 31.9 0 0 1 10 6.9l10-16.6A48.9 48.9 0 0 0 77.7 0a48.1 48.1 0 0 0-13 1.8 49 49 0 0 0-17.1 8.6V47a28.6 28.6 0 0 1 2.3-9.7zm41.2 38.8a32 32 0 0 1-6.4 2.4 28.1 28.1 0 0 1-7 .9A28.9 28.9 0 0 1 66 77a30.7 30.7 0 0 1-16.1-16.2 29 29 0 0 1-2.3-9.8v7.2a37.1 37.1 0 0 1-3 14.8 39.3 39.3 0 0 1-4.4 7.7l2.8 3.1a50.7 50.7 0 0 0 10 7.7 48.7 48.7 0 0 0 11.7 5 48.1 48.1 0 0 0 13 1.8 48.9 48.9 0 0 0 31.8-11.7V49H91.1v27.1z"/>
              </svg>
            </a>
          </li>
          <li style={{ marginLeft: '3rem'}}>
            Product Features
          </li>
        </ul>
      </div>
    </nav>

    <div className='scroll-snap-none' style={{ padding: '0 6rem' }}>
      <div className='container' style={{ padding: '10rem 0' }}>
        <Heading>Product Features</Heading>
        <Text small>
          Discover the key features of our products and how they can benefit your business online. Contact us
          at <a href='mailto:contact@jgtech.uk'>contact@jgtech.uk</a> for more information or to request a demo.
          Our team is happy to help you find the right solutions for your business needs.
        </Text>

        {sections.map(section => <div id={convertToSlug(section.title)} className='row' style={{ padding: '5rem 0' }}>
          <Heading>{section.title}</Heading>
          {section.items.map(item => <div className='col-12 col-sm-6' style={{ paddingTop: '3rem' }}>
            <SubHeading small>{item.heading}</SubHeading>
            <Text small>{item.text}</Text>
          </div>)}
        </div>)}
      </div>
    </div>

    <Footer />
  </>
}

export default Features
