/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react'
import Blob from './Blob'

let oldMousePoint = { x: 0, y: 0 }
let hover = false

let mouseMove = function (e, blob, canvas) {
  let pos = {
    x: blob.center.x + canvas.getBoundingClientRect().x,
    y: blob.center.y + canvas.getBoundingClientRect().y
  }
  let diff = { x: e.clientX - pos.x, y: e.clientY - pos.y }
  let dist = Math.sqrt((diff.x * diff.x) + (diff.y * diff.y))
  let angle = null

  blob.mousePos = { x: pos.x - e.clientX, y: pos.y - e.clientY }

  if (dist < blob.radius && hover === false) {
    let vector = { x: e.clientX - pos.x, y: e.clientY - pos.y }
    angle = Math.atan2(vector.y, vector.x)
    hover = true
  } else if (dist > blob.radius && hover === true) {
    let vector = { x: e.clientX - pos.x, y: e.clientY - pos.y }
    angle = Math.atan2(vector.y, vector.x)
    hover = false
  }

  if (typeof angle == 'number') {
    let nearestPoint = null
    let distanceFromPoint = 100

    blob.points.forEach((point) => {
      if (Math.abs(angle - point.azimuth) < distanceFromPoint) {
        nearestPoint = point
        distanceFromPoint = Math.abs(angle - point.azimuth)
      }
    });

    if (nearestPoint) {
      let strength = { x: oldMousePoint.x - e.clientX, y: oldMousePoint.y - e.clientY }
      strength = Math.sqrt((strength.x * strength.x) + (strength.y * strength.y)) * 10
      if (strength > 100) strength = 100
      nearestPoint.acceleration = strength / 200 * (hover ? -1 : 1)
    }
  }

  oldMousePoint.x = e.clientX
  oldMousePoint.y = e.clientY
}

const BlobButton = ({ iconCode, textColor, backgroundColor, size, className }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const blob = new Blob()
    canvas.setAttribute('touch-action', 'none')

    canvas.width = size
    canvas.height = size

    window.addEventListener('pointermove', (e) => mouseMove(e, blob, canvas))

    blob.canvas = canvas
    blob.textColor = textColor
    blob.color = backgroundColor
    blob.radius = size / 4
    blob.numPoints = 11
    blob.icon = iconCode
    blob.init()
    blob.render()
  }, [])

  return <canvas ref={canvasRef} className={className} width={size} height={size} />
}

export default BlobButton
