import React from 'react'
import { Parallax } from 'react-scroll-parallax'

const getRandomNum = (min, max) => { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const WhyUsIcon = ({ color, icon }) => {
  return <Parallax speed={5}>
    <div style={{ position: 'relative', marginTop: 60, marginBottom: 60, width: '100%', maxWidth: 200, margin: 'auto', aspectRatio: '1 / 1' }}>
      <Parallax translateX={[getRandomNum(-30, 30), getRandomNum(-30, 30)]} translateY={[getRandomNum(-30, 30), getRandomNum(-30, 30)]} style={{ position: 'absolute', width: '100%', aspectRatio: '1 / 1' }}>
        <div style={{ backgroundColor: `rgba(${color},0.1)`, aspectRatio: '1 / 1', borderRadius: '30%' }} />
      </Parallax>

      <Parallax translateX={[getRandomNum(-20, 20), getRandomNum(-20, 20)]} translateY={[getRandomNum(-20, 20), getRandomNum(-20, 20)]} style={{ position: 'absolute', width: '80%', top: '10%', left: '10%', aspectRatio: '1 / 1' }}>
        <div style={{ backgroundColor: `rgba(${color},0.2)`, aspectRatio: '1 / 1', borderRadius: '30%' }} />
      </Parallax>

      <div style={{ position: 'relative', display: 'flex', width: '70%', top: '15%', left: '15%', backgroundColor: `rgba(${color},1)`, aspectRatio: '1 / 1', borderRadius: '30%' }}>
        <img src={icon} style={{ width: '60%', left: '20%', position: 'relative' }} alt='' />
      </div>
    </div>
  </Parallax>
}

export default WhyUsIcon
