import React from 'react'
import { Parallax } from 'react-scroll-parallax'
import Card from '../Card'
import accessibility1 from '../../../images/features/accessibility/accessibility1.png'
import accessibility2 from '../../../images/features/accessibility/accessibility2.png'
import accessibility3 from '../../../images/features/accessibility/accessibility3.png'
import accessibility4 from '../../../images/features/accessibility/accessibility4.png'

const Accessibility = () => {
  return <div style={{ position: 'relative', height: '90vh', maxWidth: 800, margin: 'auto' }}>
    <div style={{ position: 'relative', height: '50vh', top: '50%', transform: 'translateY(-50%)' }}>
      <Parallax speed={14} style={{ position: 'absolute', left: '0%', top: '0%', width: '40%' }}>
        <Card><img src={accessibility1} style={{ width: '100%' }} alt='Mother assisting child with computer' /></Card>
      </Parallax>
      <Parallax speed={12} style={{ position: 'absolute', left: '50%', top: '10%', width: '40%' }}>
        <Card><img src={accessibility2} style={{ width: '100%' }} alt='Elderly man receiving assistance with mobile phone' /></Card>
      </Parallax>
      <Parallax speed={20} style={{ position: 'absolute', left: '20%', top: '40%', width: '37%' }}>
        <Card><img src={accessibility3} style={{ width: '100%' }} alt='Blind man receiving assitance with computer' /></Card>
      </Parallax>
      <Parallax speed={15} style={{ position: 'absolute', left: '60%', top: '70%', width: '35%' }}>
        <Card><img src={accessibility4} style={{ width: '100%' }} alt='Elderly couple using mobile phone to shop' /></Card>
      </Parallax>
    </div>
  </div>
}

export default Accessibility
