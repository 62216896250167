import React from 'react'
import Card from '../Card'
import { Parallax } from 'react-scroll-parallax'
import pound from '../../../images/features/pound.svg'
import rotate from '../../../images/features/arrow-rotate.png'
import rotateBlack from '../../../images/features/arrow-rotate-black.png'
import subscriptionCard from '../../../images/features/subscription-card.png'

const CalendarItem = ({ backgroundColor, easing = 'ease', children }) => {
  return <Parallax easing={easing} scale={[0, 1]} style={{ width: 'calc(20% - 10px)', aspectRatio: '1 / 1', margin: 5 }}>
    <Card style={{ width: '100%', height: '100%', backgroundColor }}>{children}</Card>
  </Parallax>
}

const Subscriptions = () => {
  return <div style={{ position: 'relative', maxHeight: '100vh', maxWidth: '60vh', aspectRatio: '1 / 1', margin: 'auto' }}>
    <div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
      <Parallax rotate={[0, 180]} style={{ width: '90%', aspectRatio: '1 / 1'}}>
        <img src={rotate} style={{ width: '100%' }} alt='' />
      </Parallax>
    </div>

    <div style={{ position: 'absolute', width: '90%', aspectRatio: '1 / 1', top: '50%', transform: 'translateY(-50%)' }}>
      <div style={{ position: 'absolute', display: 'flex', flexWrap: 'wrap', width: '90%', top: '50%', left: '10%', transform: 'translateY(-50%)' }}>
        <div style={{ width: '20%' }}></div>
        <Card style={{ width: 'calc(20% - 10px)', aspectRatio: '1 / 1', margin: 5 }} />
        <Card style={{ width: 'calc(20% - 10px)', aspectRatio: '1 / 1', margin: 5 }} />
        <Card style={{ width: 'calc(20% - 10px)', aspectRatio: '1 / 1', margin: 5 }} />
        <Card style={{ width: 'calc(20% - 10px)', aspectRatio: '1 / 1', margin: 5 }} />
        <Card style={{ width: 'calc(20% - 10px)', aspectRatio: '1 / 1', margin: 5 }} />
        <CalendarItem backgroundColor='#5EC630' easing={[0,0, .5, 1.5]}>
          <img src={pound} style={{ height: '50%', position: 'relative', top: '25%', left: '33%' }} alt='' />
        </CalendarItem>
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2' />
        <CalendarItem backgroundColor='#b7e8a2'/>
        <CalendarItem backgroundColor='#5EC630' easing={[0,0, .5, 1.5]}>
          <img src={pound} style={{ height: '50%', position: 'relative', top: '25%', left: '33%' }} alt='' />
        </CalendarItem>
        <CalendarItem backgroundColor='#b7e8a2' />
      </div>

      <div style={{ position: 'absolute', width: '100%', bottom: 0, transform: 'translateY(-50%)' }}>
        <Parallax scale={[0, 1]} easing='easeOutBack' style={{ position: 'relative', left: '50%', top: '50%', width: '50%' }}>
          <Card>
            <img src={subscriptionCard} style={{ width: '100%' }} alt='Subscription activated notification card' />
            <div style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '15%', left: '10%' }}>
              <Parallax rotate={[0, 720]} easing='easeInOut' style={{ width: '100%', aspectRatio: '1 / 1'}}>
                <img src={rotateBlack} style={{ width: '100%' }} alt='' />
              </Parallax>
            </div>
          </Card>
        </Parallax>
      </div>
    </div>
  </div>
}

export default Subscriptions
