import React from 'react'
import './card.css'

const Card = ({ children, ...props }) => {
  return <div className='card' {...props}>
    {children}
  </div>
}

export default Card
