import React from 'react'
import typeStyleDefaults from './typeStyleDefaults'

const withTypeProps = (Component) => {
  return ({ children, color, center, ...props }) => <Component defaultStyles={{
    ...typeStyleDefaults,
    color: color || '#535353',
    textAlign: !!center ? 'center' : 'left'
  }} {...props}>
    {children}
  </Component>
}

export default withTypeProps
