import React from 'react'
import shield1 from '../../../images/features/authentication/shield-1.svg'
import shield2 from '../../../images/features/authentication/shield-2.svg'
import shield3 from '../../../images/features/authentication/shield-3.svg'
import { RandomReveal } from 'react-random-reveal'

const Authentication = () => {
  return <div style={{ position: 'relative', height: '90vh', maxWidth: 800, margin: 'auto' }}>
    <div style={{
      width: '70%',
      maxWidth: 800,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%'
    }}>
      <div style={{ width: '100%', position: 'absolute', transform: 'translate(-50%, -50%)', left: '50%', top: '50%' }}>
        <img className="shield-3" src={shield3} style={{ width: '100%' }} alt='' />
      </div>
      <div style={{ width: '85%', position: 'absolute', transform: 'translate(-50%, -50%)', left: '50%', top: '50%' }}>
        <img className="shield-2" src={shield2} style={{ width: '100%' }} alt='' />
      </div>
      <div style={{ width: '70%', position: 'absolute', transform: 'translate(-50%, -50%)', left: '50%', top: '50%' }}>
        <img className="shield-1" src={shield1} style={{ width: '100%' }} alt='' />
      </div>
    </div>
    <div className="hourglass-container"
         style={{ position: 'relative', width: '15%', transform: 'translate(-50%, -50%)', left: '50%', top: '50%' }}>
      <div className="hourglass"/>
    </div>
    <div style={{
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      color: 'white',
      letterSpacing: '1rem',
      fontSize: '5rem',
      fontFamily: 'monospace',
      marginLeft: '0.5rem'
    }}>
      <RandomReveal
        isPlaying
        duration={3.5}
        characters='*****'
        onComplete={() => ({
          shouldRepeat: true,
          delay: 1.4
        })}
      />
    </div>
  </div>
}

export default Authentication
