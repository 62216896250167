import React from 'react'
import SubHeading from '../Typography/SubHeading'
import Text from '../Typography/Text'
import FadeInOnScroll from '../FadeInOnScroll'

const MoonSection = ({ title, description, image, alt }) => {
  return <div className='moon-section' style={{ position: 'relative', width: '100vw', height: '100vh' }}>
    <div style={{ maxWidth: '80vw', height: '100vh', margin: 'auto' }}>
      <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}>
        <FadeInOnScroll duration={1300} offset={50} direction='horizontal'>
          <img src={image} alt={alt} style={{ maxHeight: '70vh', maxWidth: '80vw' }} />
        </FadeInOnScroll>
      </div>
      <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', paddingLeft: '25%', width: '100%' }}>
        <FadeInOnScroll duration={1300} offset={100} direction='horizontal'>
          <SubHeading color='white'>{title}</SubHeading>
          <Text color='white'>{description}</Text>
        </FadeInOnScroll>
      </div>
    </div>
  </div>
}

export default MoonSection
