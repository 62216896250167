import React, { useState } from 'react'
import FadeInOnScroll from '../FadeInOnScroll'
import './navigation.css'

const NavItem = ({ sectionKey, currentSection, text, color }) => {
  return <li className='nav__item'>
    <a href={`#${sectionKey}`}>{text}</a>
    {sectionKey === currentSection && <div className='nav__item__dot' style={{ backgroundColor: color }} />}
  </li>
}
const Navigation = ({ sections }) => {
  const [currentSection, setCurrentSection] = useState('products')

  if (sections) {
    Object.entries(sections).forEach(([key, element]) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && currentSection !== key) {
            setCurrentSection(key)
          }
        },
        { root: null, threshold: 0.1 }
      )
      observer.observe(element)
    })
  }

  const background = currentSection === 'products' ? 'rgba(0,0,0,0.2)' : 'rgba(255,255,255,0.7)'
  const color = currentSection === 'products' ? 'white' : '#535353'

  return <nav style={{
    position: 'sticky',
    top: 0,
    zIndex: 9999,
    height: 0,
    width: '100vw',
    backgroundColor: background,
    transitionProperty: 'color',
    transitionDuration: '0.2s'
  }}>
    <FadeInOnScroll duration={1000} offset={0} style={{ backdropFilter: 'blur(10px)' }}>
      <div style={{
        transitionProperty: 'background-color, color',
        transitionDuration: '0.2s',
        paddingTop: 15,
        color,
        backgroundColor: background
      }}>
        <ul className='nav__list nav__list--home'>
          <li style={{ color, transition: '0.2' }}>
            <a href='#home'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.5 98.3" fill="currentColor" height={50}>
                <path
                  d="M30.3 62.1a48.4 48.4 0 0 1-1.8-13v9.1a18.3 18.3 0 0 1-1.5 7.4 19.1 19.1 0 0 1-10.2 10.1 18.6 18.6 0 0 1-7.4 1.5 18.9 18.9 0 0 1-5-.7 18.2 18.2 0 0 1-4.6-1.9V95a39.3 39.3 0 0 0 9.6 1.2 37.1 37.1 0 0 0 14.8-3 38.3 38.3 0 0 0 12.2-8.1 37.5 37.5 0 0 0 3.7-4.3 47.9 47.9 0 0 1-4.9-6.9 50 50 0 0 1-4.9-11.8zm5-37.8a48 48 0 0 1 7.7-10 50.2 50.2 0 0 1 4.6-4V1h-19v48a47.7 47.7 0 0 1 1.8-13 50.4 50.4 0 0 1 4.9-11.7z"/>
                <path
                  d="M49.9 37.3a30.5 30.5 0 0 1 6.5-9.6 31.2 31.2 0 0 1 9.6-6.5 29.7 29.7 0 0 1 23.6.1 31.9 31.9 0 0 1 10 6.9l10-16.6A48.9 48.9 0 0 0 77.7 0a48.1 48.1 0 0 0-13 1.8 49 49 0 0 0-17.1 8.6V47a28.6 28.6 0 0 1 2.3-9.7zm41.2 38.8a32 32 0 0 1-6.4 2.4 28.1 28.1 0 0 1-7 .9A28.9 28.9 0 0 1 66 77a30.7 30.7 0 0 1-16.1-16.2 29 29 0 0 1-2.3-9.8v7.2a37.1 37.1 0 0 1-3 14.8 39.3 39.3 0 0 1-4.4 7.7l2.8 3.1a50.7 50.7 0 0 0 10 7.7 48.7 48.7 0 0 0 11.7 5 48.1 48.1 0 0 0 13 1.8 48.9 48.9 0 0 0 31.8-11.7V49H91.1v27.1z"/>
              </svg>
            </a>
          </li>
          <NavItem text='Products' sectionKey='products' currentSection={currentSection} color={color} />
          <NavItem text='Features' sectionKey='features' currentSection={currentSection} color={color} />
          <NavItem text='Why Us' sectionKey='whyUs' currentSection={currentSection} color={color} />
          <NavItem text='Demo' sectionKey='demo' currentSection={currentSection} color={color} />
          <NavItem text='About' sectionKey='about' currentSection={currentSection} color={color} />
        </ul>
      </div>
    </FadeInOnScroll>
  </nav>
}

export default Navigation
