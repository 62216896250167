import React from 'react'
import './scrollButton.css'
import BlobButton from '../BlobButton/BlobButton'

const ScrollButton = ({ handleClick }) => {
  return <div
    className='button button--scroll'
    onClick={handleClick?.()}
  >
    <BlobButton
      iconCode={'\uf063'}
      size={140}
      backgroundColor='rgb(113, 77, 244)'
      textColor='white'
      className='button--scroll__canvas'
    />
  </div>
}

export default ScrollButton
